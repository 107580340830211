<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="true"
    ></loading>
    <div>
      <div animated fadeIn>
        <b-row>
          <!-- Personal info -->
          <b-col lg="10" offset-lg="1">
            <CCard accent-color="warning">
              <CCardHeader>
                <strong>Editar perfil</strong>
                <div class="card-header-actions">
                  <!-- <CButton 
                    class="s-bg s-bg-text" 
                    @click="submit" 
                    :disabled="disableSave"
                    style="margin-left:0.3rem;">
                    <strong>Guardar</strong>
                  </CButton>-->
                  <b-button
                    class="mr-1 s-bg s-bg-text"
                    style="margin-left:0.3rem;"
                    @click="submit"
                    :disabled="disableSave"
                  >
                    <strong>Guardar</strong>
                  </b-button>
                </div>
              </CCardHeader>
              <CCardBody>
                <b-row v-if="error">
                  <b-col cols="12">
                    <div
                      class="alert alert-danger"
                      role="alert"
                      v-for="error_message in errors"
                      v-bind:key="error_message"
                    >{{error_message}}</div>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Name and Surname -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="firstName">
                        <strong>
                          Nombre y apellido
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <b-form-input
                        type="text"
                        id="firstName"
                        v-model.trim="$v.firstName.$model"
                        placeholder="Inserta tus nombres"
                        :class="{'form-control is-invalid': $v.firstName.$error,'form-control is-valid': !$v.firstName.$invalid}"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.firstName.required">Tu nombre es necesario</b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="!$v.firstName.minLength"
                      >El primer nombre debe tener como mínimo {{$v.firstName.$params.minLength.min}} letras</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Phone -->
                  <b-col md="6">
                    <b-form-group>
                      <label for="Phone">
                        <strong>
                          Número de celular
                          <small class="alert-text">*</small>
                        </strong>
                      </label>
                      <vue-tel-input
                        v-model.trim="$v.phone_number.$model"
                        placeholder="Tu número de teléfono"
                        :class="{
                                                        'form-control is-invalid': $v.phone_number.$error,
                                                        'form-control is-valid': !$v.phone_number.$invalid }"
                        v-bind="phoneNumberProps"
                      ></vue-tel-input>
                      <b-form-invalid-feedback
                        v-if="!$v.phone_number.minLength"
                      >El número de teléfono debe tener como mínimo {{$v.phone_number.$params.minLength.min}} números.</b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="!$v.phone_number.phoneNumber"
                      >Debe tener solo números.</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- City-->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="city">
                        <strong>
                          Ciudad
                          <small class="alert-text">*</small>
                        </strong>
                        <small
                          class="form-text text-muted w-100"
                        >Busca tu ciudad y selecciona la que coincida</small>
                      </label>

                      <vue-google-autocomplete
                        v-model.trim="$v.city.$model"
                        id="city"
                        ref="city"
                        class="form-control"
                        :class="{'form-control is-invalid': $v.city.$error,
                                                    'form-control is-valid': !$v.city.$invalid}"
                        placeholder="Selecciona tu ciudad con Google Maps"
                        v-on:placechanged="getAddressData"
                        v-on:no-results-found="cityDataNotFound"
                        v-on:inputChange="cityDataChanged"
                        v-on:blur="cityBlur"
                        autocomplete="off"
                        :country="['co', 'pe']"
                        types="(cities)"
                      ></vue-google-autocomplete>

                      <b-form-invalid-feedback
                        v-if="!$v.city.required"
                      >Tu ciudad de residencia es necesaria, busca y selecciona una opción</b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="!$v.city.minLength"
                      >Tu ciudad de residencia es necesaria, busca y selecciona una opción</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Address -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="address">
                        <strong>
                          Dirección
                          <small class="alert-text">*</small>
                        </strong>
                        <small
                          class="form-text text-muted w-100"
                        >Ingresa la dirección donde deseas el servicio.</small>
                      </label>

                      <b-form-input
                        type="text"
                        id="address"
                        v-model.trim="$v.address.$model"
                        :class="{'form-control is-invalid': $v.address.$error,
                                                            'form-control is-valid': !$v.address.$invalid}"
                      ></b-form-input>

                      <b-form-invalid-feedback v-if="!$v.address.required">Tu dirección es necesaria</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Neighborhood -->
                  <b-col sm="6">
                    <b-form-group>
                      <label for="neighborhood">
                        <strong>
                          Barrio y/o referencia
                          <small class="alert-text">(opcional)</small>
                        </strong>                   
                      </label>

                      <b-form-input
                        type="text"
                        id="neighborhood"
                        v-model="neighborhood" 
                      ></b-form-input>

                      
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
                  <b-col md="6">
                    <label for="contact_email">
                      <strong>Tu email de contacto</strong>
                      <small class="alert-text">*</small>
                    </label>
                    <b-form-group>
                      <b-form-input
                        type="text"
                        id="email"
                        v-model.trim="$v.email.$model"
                        :disabled="disabledEmail"
                        :class="{'form-control is-invalid': $v.email.$error,
                                          'form-control is-valid': !$v.email.$invalid}"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.email.required"
                      >Tu email de contacto es necesario</b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="!$v.email.email"
                      >Tu email de contacto no parece valido</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <!-- Map -->
                  <b-col lg="12">
                    <section>
                      <label for="map">
                        <strong>Tu ubicación:</strong>
                      </label>
                      <br />Has clic en el icono rojo y arrastralo hasta tu lugar de residencia.
                      <GmapMap :center="center" :zoom="16" style="height: 30rem">
                        <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :label="m.label"
                          :title="m.title"
                          :clickable="true"
                          :draggable="m.draggable"
                          @click="toggleInfoWindow(m, index)"
                          @drag="updateCoordinates"
                        />
                      </GmapMap>
                      <div v-for="(marker, index) in markers">
                        <strong>Latitud:</strong>
                        {{marker.position.lat}}
                        <br />
                        <strong>Longitud:</strong>
                        {{marker.position.lng}}
                        <br />
                      </div>
                    </section>
                  </b-col>
                </b-row>
              </CCardBody>

              <CCardFooter>
                <b-row>
                  <b-col lg="12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        v-model="terms_and_conditions"
                      />
                      <label class="form-check-label" for="gridCheck">
                        Al continuar aceptas los
                        <a v-on:click="goTo('terms')">
                          <span class="text-link-color">Términos y Condiciones para</span>
                        </a>
                        de Go Guau
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col lg="12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="gridCheckPersonal"
                        v-model="personal_data"
                      />
                      <label class="form-check-label" for="gridCheckPersonal">
                        Estoy de acuerdo con la
                        <a v-on:click="goTo('terms_personal_data')">
                          <span class="text-link-color">política de tratamiento de datos personales</span>
                        </a>
                        de Go Guau
                      </label>
                    </div>
                  </b-col>
                </b-row>
                <br />
                <b-row>
                  <b-col
                    lg="3"
                    offset-lg="3"
                    md="4"
                    offset-md="2"
                    sm="6"
                    style="text-align:center; margin-bottom:1rem;"
                  >
                    <b-button
                      class="mr-1 s-bg s-bg-text"
                      style="width:100%;"
                      @click="submit"
                      :disabled="disableSave"
                    >
                      <strong>Guardar</strong>
                    </b-button>
                  </b-col>
                  <b-col lg="3" md="4" sm="6" style="text-align:center; margin-bottom:1rem;">
                    <b-button class="mr-1" style="width:100%;" @click="cancel">Cancelar</b-button>
                  </b-col>
                </b-row>
              </CCardFooter>
            </CCard>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
  requiredUnless
} from "vuelidate/lib/validators";
import firebase from "firebase";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { setupCalendar, DatePicker } from "v-calendar";
import moment from "moment";
import Loading from "vue-loading-overlay";
import vSelect from "vue-select";
import { userProfileMixin } from "../../mixins/userProfile";
import decoder from "google-geo-decoder";

const today = new Date();

const phoneNumber = number => {
  if (!!number) {
    return !!number.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);
  } else {
    return false;
  }
};

setupCalendar({
  firstDayOfWeek: 2 // Monday
});

export default {
  name: "Create",
  components: {
    Loading,
    VueGoogleAutocomplete,
    "v-date-picker": DatePicker,
    vSelect
  },
  mixins: [userProfileMixin],
  data() {
    return {
      loading: true,
      error: null,
      errors: [],
      // firstName: 'test5',
      // city: 'Popayán, Cauca, Colombia',
      // address: 'front',
      // phone_number: '+573205614051',
      firstName: null,
      city: null,
      address: null,
      neighborhood: '',
      phone_number: null,
      phoneNumberProps: {
        mode: "international",
        defaultCountry: "CO",
        enabledCountryCode: true
      },
      // email: 'test5@gmail.com',
      email: null,
      // Map attributes
      center: { lat: 2.442404, lng: -76.605833 },
      markers: [
        //user
        {
          position: { lat: 2.442404, lng: -76.605833 },
          label: "",
          draggable: true,
          title: "Casa"
        }
      ],
      disabledEmail: false,
      cities: null,
      cityMatched: null,
      terms_and_conditions: true,
      personal_data: true
    };
  },
  created() {
    this.cities = this.$store.getters.cities;
    let userId = this.$store.getters.userId;
    let userProfile = JSON.parse(this.$store.getters.userProfile);
    if (userProfile != null) {
      this.fetchProfile(userProfile);
    } else {
      if (this.$firebase.auth().currentUser.email != null) {
        this.email = this.$firebase.auth().currentUser.email;
      }
      if (this.$firebase.auth().currentUser.displayName != null) {
        this.firstName = this.$firebase.auth().currentUser.displayName;
      }
      this.$getLocation({}).then(coordinates => {
        this.markers[0].position = coordinates;
        this.center = coordinates;

        const [lon, lat] = [coordinates.lat, coordinates.lng];
        const key = "AIzaSyDH2Q73_1TQ9RqCEyqIJXDmVsey9btIhTI";
        decoder(lon, lat, key).then(res => {
          const city = res.lev2;
          const department = res.lev1;
          const country = res.country;
          const fullCity = city + ", " + department + ", " + country;
          this.city = fullCity;
          this.cityFilter();
        });
      });
    }
    this.loading = false;
  },
  computed: {
    emailInLowerCase() {
      return this.email.toLowerCase().trim();
    },
    disableSave() {
      if (this.terms_and_conditions && this.personal_data) {
        return false;
      } else {
        return true;
      }
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2)
    },
    city: {
      required,
      minLength: minLength(4)
    },
    address: {
      required
    },
    email: {
      required,
      email
    },
    phone_number: {
      required,
      minLength: minLength(7),
      phoneNumber
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = true;
        this.errors = ["No has completado correctamente tus datos"];
        this.scrollToTop();
      } else {
        this.sendRequest();
      }
    },
    scrollToTop() {
        window.scrollTo(0, 0);
      },
    sendRequest() {
      let userId = this.$store.getters.userId;
      let data = {
        firstName: this.firstName,
        phone_number: this.phone_number,
        address: this.address,
        neighborhood: this.neighborhood,
        city: this.city,
        cityMatched: this.cityMatched,
        email: this.emailInLowerCase,
        position: {
          lat: this.markers[0].position.lat,
          lng: this.markers[0].position.lng,
          accuracy: this.markers[0].position.accuracy
        },
        userId
      };
      this.postUserProfile(data);
    },
    postUserProfile(userProfile) {
      let createUserProfile = this.$firebase
        .functions()
        .httpsCallable("createUser");
      this.loading = true;
      createUserProfile(userProfile)
        .then(result => {
          this.error = false;
          if (result.data != null && !result.error) {
            let client = result.data.data[0]
            this.setUser(client);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log("error create user:", error);
        });
    },
    setUser(userData) {
      this.$store
        .dispatch("fetchUser", { userId: userData.user_id })
        .then(response => {
          let userData = response;
          if (userData) {
            this.$store.commit("defUserDbId", userData.id);
            this.$store.commit("defUserProfile", JSON.stringify(userData));

            this.$router.replace("/users/profile/");

          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
        });
    },
    getAddressData(addressData, placeResultData, id) {
      // console.log(addressData);
      // console.log(placeResultData)
      let country = addressData.country;
      // let locality = addressData.locality
      let fomatted_address = placeResultData.formatted_address;
      this.currencySelected =
        country == "Perú" || country == "Peru" ? "PEN" : "COP";
      // this.city = locality != null ? locality :  fomatted_address
      this.city = fomatted_address;

      this.cityFilter();

      // console.log('cityFound: ', this.cityMatched)
      // this.$v.$touch()
    },
    cityDataNotFound(address) {
      console.log('cityDataNotFound')
      this.$v.city = null;
      // this.$v.$touch();
    },
    cityDataChanged(inputVal) {
      console.log('cityDataChanged')
      if (inputVal.newVal != this.city) {
        this.city = null;
      }
      this.$v.city.$touch();
      // this.$v.$touch();
    },
    cityBlur() {
      this.$v.city.$touch();
    },
    cityFilter() {
      let cityFound = this.cities.find(city => city.nombre == this.city);

      if (cityFound == undefined) {
        this.cityMatched = {
          value: 0,
          label: this.city
        };
      } else {
        this.cityMatched = {
          value: cityFound.id,
          label: cityFound.nombre
        };
      }
    },
    updateCoordinates(location) {
      this.markers[0].position = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };
    },
    fetchProfile(userProfile) {
      this.firstName = userProfile.nombre;
      this.phone_number = userProfile.celular;
      this.city = userProfile.ciudad;
      this.address = userProfile.direccion;
      this.neighborhood = userProfile.barrio;
      this.email = userProfile.email;
      this.city = userProfile.ciudad;

      let lat = userProfile.ubicacion.lat;
      let lng = userProfile.ubicacion.lng;

      this.center.lat = +lat
      this.center.lng = +lng
      this.markers[0].position.lat = +lat
      this.markers[0].position.lng = +lng  

      this.disabledEmail = true;

      if (this.cityMatched == null) {
        let cityFound = this.cities.find(city => city.nombre == this.city);

        if (cityFound == undefined) {
          this.cityMatched = {
            value: 0,
            label: this.city
          };
        } else {
          this.cityMatched = {
            value: cityFound.id,
            label: cityFound.nombre
          };
        }
      }
    },
    cancel() {
      this.$router.replace("/users/profile");
    },
    goTo(to) {
      if (to == "terms") {
        let route = this.$router.resolve({ path: "/pages/terms" });
        window.open(route.href, "_blank");
      } else if (to == "terms_personal_data") {
        let route = this.$router.resolve({ name: "pages-personaldata" });
        window.open(route.href, "_blank");
      }
    }
  }
};
</script>

<style scoped src="vue-tel-input/dist/vue-tel-input.css">
</style>